import PropTypes from 'prop-types';
import React from 'react';
import Header from './Header';
import PageNumber from './PageNumber';
import BillTotalContent from './BillTotalContent';
import TableHeader from '../TableHeader/TableHeader';
import {
  salesInvoiceDetailConfig,
  SalesInvoiceDetailTableBody,
} from '../../v1/views/SalesInvoice/SalesInvoiceDetailConfig';
import { AbiDetailRow } from '../../views/fundamentals/abi/detail/config';

const PrintBody = ({
  date,
  miti,
  print,
  title,
  company,
  message,
  dataList,
  printInfo,
  billSummary,
  invoiceDetail,
  paymentDetail,
  billTotalList,
  dataMiscellaneousList,
  agentInfo,
  agentExist,
  printInfoBill,
  pageConfig,
  isAbbreviatedInvoice,
  totalQuantity,
  printCountInfo,
  receiverDetails,
  senderDetails,
  printDetails,
  invoiceData,
  isCancel,
}) => {
  return (
    dataList.length && dataList.map((data, key) => {
      return (
        <div className={`print-body zoom-less-reduce upaya-invoice ${isCancel && 'isCancelled'}`}>
          <div className='card-body'>
            <Header
              company={company}
              divClassName={key === 0 ? 'first-header-bar header-border-bottom' : 'header-bar header-border-bottom'}
              date={date}
              miti={miti}
              pageTitle={<h2>{title}</h2>}
              dataMiscellaneousList={dataMiscellaneousList}
              paymentModeStatus
              paymentMode={paymentDetail.mode}
              printCountInfo={printCountInfo}
            />
            <div className='sender_receiver-details'>
              <div className='input'>
                <div className='trip_no'>
                  <h3>Trip No.</h3>
                  <p>{invoiceData?.summary?.referenceNumber || 'N/A'}</p>
                </div>
                <div className='invoice_id'>
                  <h3>Invoice ID</h3>
                  <p>{printDetails?.invoiceNumber || 'N/A'}</p>
                </div>
              </div>
              <div className='details'>
                <div className='input one'>
                  {senderDetails?.map((a) =>{
                    return(
                      <>
                        <p className={a.class}>{a.title }<span>{a.value}</span></p>
                      </>
                    )
                  })}
                </div>
                <div className='input two'>
                  {receiverDetails?.map((a) =>{
                    return(
                      <>
                        <p className={a.class}>{a.title}<span>{a.value}</span></p>
                      </>
                    )
                  })}
                </div>
              </div>
            </div>
            <div
              className={` fixed-table-wrapper ${!data.footer ? 'fixed-table-height ' : 'total-footer'}`}
            >
              <table
                className={ 'batch_not_exist data-table'}>
                <TableHeader
                  headerDetails={pageConfig.header} 
                  filterHeaderLabel={false}
                />
                <tbody>
                  {
                    data.list.map((salesInvoice, keyTest) => (
                      <SalesInvoiceDetailTableBody data={salesInvoice} index={keyTest} enableUpdate={false}
                        printInfoBill={printInfoBill}
                        isAbbreviatedInvoice={isAbbreviatedInvoice}/>
                    ))
                  }
                  {data.remainingLines.length
                    ? data.remainingLines.map((i) => (
                      <tr className='blank-tr'>
                        <td></td>
                        <td className='sku service'></td>
                        <td className='description'></td>
                        <td className='quantity'></td>
                        <td className='rate'></td>
                        <td className='amount'></td>
                      </tr>
                    ))
                    : null}
                </tbody>
              </table>
            </div>
            {data.footer
            && (
              <div className='footer-totalList'>
                <table className='print-active total-section-wrapper footer clearfix'>
                  <BillTotalContent
                    totalList={billTotalList}
                    totalValue={billSummary.grossValue}
                    paymentMode={billSummary.paymentMode}
                    invoiceDetail={invoiceDetail}
                    print={print}
                    batchEnabled ={false}
                    printInfo={printInfo}
                    totalInWords={billSummary.totalInWords}
                    remarksStatus
                    remarks={billSummary.remarks}
                    agentInfo={agentInfo}
                    totalQuantity={totalQuantity}
                    invoiceData={invoiceData}
                  />
                </table>
              </div>
            )}
            {
              !data.footer && (
                <div className='padding-top-8 continue-nxt default-horizontal-margin-24'>
                  <span>{message}</span>
                </div>
              )
            }
          </div>
          <div className='footer-block'>
            <PageNumber value={data.page} totalPage={dataList.length}/>
          </div>
        </div>
      );
    })
  );
};

PrintBody.defaultProps = {
  signatureTitle: 'Authorized Signature',
  lineMargin: 'padding-r-8',
  invoiceDetail: { title: '', value: '' },
};

PrintBody.propTypes = {
  totalList: PropTypes.array,
  agentInfo: PropTypes.instanceOf(Object),
};

export default PrintBody;
