import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, LinearProgress } from '../../../../components/BillingMDC';
import '../../../../styles/printStyle.css';
import history from '../../../../utils/history';
import { clone } from '../../../../utils/arrayProcessor';
import * as filterUtil from '../../../../utils/filterUtil';
import {
  getBillFooterSummary,
  getBillSummaryDetails,
  getBillTotalList,
  MESSAGE,
  PRINT_COPY,
  TITLE,
  config,
  getTotals,
  getSenderReceiverDetails,
  CASH_MODE,
} from './config';
import PrintBody from '../../../../components/PrintComponent/PrintBody';
import { SERVICE_INVOICE_BASE } from '../../../../data/enums/Route';
import { CLIENT, GET_BATCH_TH } from '../../../../data/enums/enums';
import { salesInvoiceApi } from '../../../common/base.api';
import { has } from '../../../../utils/hasOwnProperty';
import * as printService from '../../../common/print.service';
import * as snackService from '../../../common/snack.service';
import BillingSnackBar from '../../../../components/BillingMDC/BillingSnackbar';
import PageButtonWrapper from '../../../common/PageButtonWrapper';
import * as httpUtils from '../../../../utils/httpUtils';
import * as appConfig from '../../../../v1/config';
import DetailView from '../../../common/detail/DetailView';
import ReportView from '../../../../components/PrintComponent/ReportView';
import numberToWords from '../../../../utils/numberToTextConverter';
import { isObjectEmpty } from '../../../../utils/objectProcessor';
import { addIndexInPrintDataSet } from '../../../common/common';
import InvoiceDetailsStyled from './InvoiceDetailsStyled';
import { groupPrintDataSet } from '../../../common/print.service';
import html2pdf from 'html2pdf.js';
import { validateEmail } from '../../../../utils/conversion';
import tableConfig from '../config';
import { EMAIL_RULE } from '../../../../data/enums/enums';
import {ConfirmationDialog} from '../Dialog';

const propTypes = {
  postPrintHandler: PropTypes.func,
  batchEnabled: PropTypes.bool,
};

const defaultProps = {
  postPrintHandler: () => window.close(),
  batchEnabled: false,
};

class InvoiceDetails extends Component {
  handleCancelClick = () => {
    history.push(`/${SERVICE_INVOICE_BASE}`);
  };

  printInvoice = async () => {
    const { printDetails } = this.state;
    const search = location.search;
    const sendEmail = new URLSearchParams(search).get('sendEmail');
    const self = this;
    await this.groupPrintDataSetNew();
    self.setState({ printButtonClicked: true }, () => {
      setTimeout(() => {
        window.print();
      }, 500);
    });

    if (sendEmail) {
      return;
    } else {
      window.onafterprint = function () {
        self.setState({ printButtonClicked: false });
        // self.props.afterPrint(user.idUsers, printDetails, this.props.afterPrintCallBack);
        printService.postPrint(printDetails, self.handleAfterPrint);
      };
      // todo handle if error while notifying server
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { printButtonClicked } = this.state;
    if (printButtonClicked && prevState.printButtonClicked !== printButtonClicked) {
      this.getDetail();
    }
  }

  // fetch the detail from the server.
  getDetail = async (clientType) => {
    const { invoiceNumber, userDetail, printDetails, firstCopyTaxInvoice } = this.state;
    this.setState({ loading: true });
    await salesInvoiceApi
      .getPrintDetails({
        invoice_number: invoiceNumber,
        user_id: userDetail.id,
      })
      .then(response => {
        if ((response.status == '200') & response.success) {
          let { printInfo } = this.state;
          const { invoiceDetail, paymentDetail } = this.state;
          const { company } = this.props;
          const { salesInvoice = {} ,Total} = response.data;
          const totalQuantity = salesInvoice.salesDetail.reduce((acc, curr) => {
            return acc + curr.quantity;
          }, 0);
          const subTotal = response.data ? response.data.subTotal || 0 : 0;
          const billTotalList = getBillTotalList({
            subTotal,
            ...salesInvoice,
          });
          const dataMiscellaneousList = getBillSummaryDetails(salesInvoice);
          const billSummary = getBillFooterSummary(salesInvoice);
          const invoiceData = {
            ...salesInvoice,
            ...getTotals({ ...salesInvoice, subTotal }),
          };
          const data = {
            list: salesInvoice.salesDetail,
            summary: { ...invoiceData,total:Total },
          };
          let companyInfo = { ...company };
          const receiverDetailList = getSenderReceiverDetails({
            ...salesInvoice?.receiverDetail,
            cardTitle: 'Receiver Details',
            branch: salesInvoice?.receiverBranch,
            address: salesInvoice?.additionalInfo?.dropoffAddress,
          });
          const senderDetailList = getSenderReceiverDetails({
            ...salesInvoice?.senderDetail,
            cardTitle: 'Sender Details',
            branch: salesInvoice?.senderBranch,
            address: salesInvoice?.additionalInfo?.pickupAddress,
          });

          const paymentModeAlias =salesInvoice?.paymentModeAlias?.toUpperCase();
          paymentDetail.mode = paymentModeAlias==='CUSTOMER'?'CREDIT': paymentModeAlias==='TO_PAY'?'TO PAY': salesInvoice?.paymentModeAlias;
          invoiceDetail.value = salesInvoice?.userDetail?.name;
          if (this.client === CLIENT.ROSIA) {
            companyInfo = response.data.company || company;
            if (has.call(response.data, 'actionUserDetail')) {
              userDetail.title = response.data.actionUserDetail.name;
              invoiceDetail.value = response.data.actionUserDetail.name;
            }
            printDetails.invoiceNumber = salesInvoice.invoiceNumber;
            printDetails.printedBy = userDetail.id;
          } else {
            userDetail.title = this.props.user.name;
            userDetail.id = this.props.user.idUsers;
          }
          const isCancelled =salesInvoice?.isCancelled;
          const salesPrintInfo = printService.dataProcessor(salesInvoice, userDetail);
          printInfo = salesPrintInfo?.printInfo;

          this.setState(
            {
              data,
              totalQuantity: totalQuantity,
              billSummary,
              skuList: salesInvoice.salesDetail,
              invoiceId: salesInvoice.invoiceNumber,
              date: moment(salesInvoice.date).format('DD MMM YYYY'),
              miti: salesInvoice.mitiTitle,
              entered_by: salesInvoice?.userDetail?.name,
              billTotalList,
              dataMiscellaneousList,
              invoiceDetail,
              paymentDetail,
              printInfo,
              userDetail,
              company: companyInfo,
              senderDetails: senderDetailList,
              receiverDetails: receiverDetailList,
              loading: false,
              firstCopyTaxInvoice: false,
              isCancel:isCancelled,
            },
            () =>{
              const search = location.search;
              const sendEmail = new URLSearchParams(search).get('sendEmail');
              if (sendEmail || clientType === CLIENT.DBS) {
                this.printDocument(clientType);
              } else {
                if (firstCopyTaxInvoice || this.client === CLIENT.ROSIA) {
                  setTimeout(() => {
                    this.printInvoice();
                  }, 500);
                }
              }
            },
          );
      }}).catch(error => {
        const snack = snackService.generateFailureMessage('Error while loading!');
        this.setState({ loading: false, snack });
      });
  };

  printDocument = async clientType => {
    const { company } = this.props;
    const { billSummary, printInfo, entered_by, invoiceNumber, data } = this.state;
    const customerInfo =
      data?.summary?.paymentModeAlias === 'TO_PAY' ? data?.summary?.receiverDetail : data?.summary?.senderDetail;
    const search = location?.search;
    const email = customerInfo?.email || new URLSearchParams(search)?.get('email');
    if (validateEmail(email)) {
      await this.groupPrintDataSetNew();
      this.setState({ pdfButtonClicked: true });
      const element = window.document.getElementById('divToPrint').innerHTML;
      // await html2pdf().from(element).save(); //to download pdf on screen for checking
      await html2pdf()
        .from(element)
        .outputPdf()
        .then(async pdf => {
          // This logs the right base64
          const baseEnconder = btoa(pdf);
          const orderId = data?.summary?.referenceNumber || new URLSearchParams(search)?.get('orderId');
          const name = customerInfo?.title || new URLSearchParams(search)?.get('name');
          const formData = new FormData();
          const byteCharacters = atob(baseEnconder);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const file = new Blob([byteArray], { type: 'application/pdf;base64' });
          formData.append('file', file, 'invoice.pdf');
          formData.append('email', email);
          formData.append('companyPhoneNumber', company.phone);
          formData.append('companyTitle', company.title);
          formData.append('UserName', name);
          formData.append('totalAmount', billSummary.grossValue);
          formData.append('enteredBy', entered_by);
          formData.append('invoiceNumber', invoiceNumber);
          if (clientType === CLIENT.DBS) {
            const snack = snackService.generateSuccessMessage('Email sent succuessfully');
            this.setState({ loading: false, snack });
            this.setState({ snack });
          } else {
            history.push({
              pathname: '/email-template',
              state: { orderId, email, name },
            });
          }
          await salesInvoiceApi.getPdf(formData);
        });
    } else {
      const snack = snackService.generateFailureMessage('Sender/Receiver does not have a mail account');
      this.setState({ loading: false, snack });
      this.setState({ snack });
    }

    this.setState({ pdfButtonClicked: false });
  };

  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.printInvoice();
    }
  };

  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };
  handleSendEmail =() =>{
      this.setState({sendmail:true});
  }
  // handleSendEmail = () => {
  //   const { location } = this.props;
  //   const { data } = this.state;
  //   salesInvoiceApi
  //     .getEmailUrl({
  //       billing_user_id: this.props.user.idUsers,
  //       invoice_number: location?.state?.id,
  //     })
  //     .then(response => {
  //       this.setState({ loading: false });
  //       if ((response.status == '200') & response.success) {
  //         const customerInfo =
  //           data?.summary?.paymentModeAlias === 'TO_PAY' ? data?.summary?.receiverDetail : data?.summary?.senderDetail;
  //         const email = customerInfo?.email;
  //         if (validateEmail(email)) {
  //           window.open(`${response?.data}?sendEmail=true`);
  //         } else {
  //           const snack = snackService.generateFailureMessage('Sender/Receiver does not have a mail account');
  //           this.setState({ loading: false, snack });
  //           this.setState({ snack });
  //         }
  //       } else {
  //         const snack = snackService.generateFailureMessage('Error while loading!');
  //         this.setState({ loading: false, snack });
  //       }
  //     });
  // };

  renderPrintContent = () => {
    const {
      date,
      miti,
      print,
      dataList,
      printInfo,
      billSummary,
      invoiceDetail,
      paymentDetail,
      billTotalList,
      firstCopyTaxInvoice,
      dataMiscellaneousList,
      totalQuantity,
      senderDetails,
      receiverDetails,
      printDetails,
      data,
      pdfButtonClicked,
    } = this.state;
    const { company,isCancel } = this.state;
    const printInfoBill = company.printInfo;
    const printBody = [];
    const agentInfo = dataMiscellaneousList.slice(-1)[0];
    const countInfo = printInfo?.find(a => a?.title === 'Print Count');
    const printCountExist = !!(countInfo && countInfo.value);
    const printCountInfo = printCountExist ? countInfo : null;
    for (let count = 0; count < (printInfoBill? printInfoBill.count:1); count++) {
      if (count === 1 && pdfButtonClicked) {
        break;
      }
      const title = !printCountExist ? (count === 0 ? TITLE.ORIGINAL : TITLE.ORIGINAL2) : TITLE.COPY;
      printBody.push(
        <PrintBody
          date={date}
          miti={miti}
          print={print}
          title={title}
          company={company}
          dataList={dataList}
          printInfo={printInfo}
          billSummary={billSummary}
          message={MESSAGE.NEXT_PAGE}
          invoiceDetail={invoiceDetail}
          paymentDetail={paymentDetail}
          billTotalList={billTotalList}
          dataMiscellaneousList={[]}
          agentInfo={agentInfo}
          printInfoBill={printInfoBill}
          pageConfig={this.pageConfig}
          printCountInfo={printCountInfo}
          senderDetails={senderDetails}
          receiverDetails={receiverDetails}
          printDetails={printDetails}
          totalQuantity={totalQuantity}
          invoiceData={data}
          isCancel={isCancel}
        />,
      );
    }
    return printBody;
  };

  constructor(props) {
    super(props);
    const {match} =this.props;
    const id=match.params.id.replace(/%2F/g, '/')
    this.state = {
      date: '',
      miti: '',
      invoiceNumber: id,
      entered_by: '',
      firstCopyTaxInvoice: false,
      print: true,
      loading: false,
      printButtonClicked: false,
      pdfButtonClicked: false,
      paymentDetail: {
        status: true,
        mode: 'CREDIT',
      },
      data: {
        list: [],
        summary: {},
      },
      billSummary: getBillFooterSummary({}),
      dateTime: {
        date: filterUtil.getCurrentDay(),
        time: filterUtil.getCurrentTime(),
      },
      userDetail: {
        id: '',
        title: '',
      },
      company: isObjectEmpty(props.company) ? { printInfo: {} } : props.company,
      invoiceDetail: { title: 'Invoiced By', value: '' },
      skuList: [],
      dataList: [],
      printInfo: [],
      printDetails: {
        transaction: 'SALES',
        invoiceNumber: id,
        printedBy: '',
      },
      totalQuantity: 0,
      billTotalList: getBillTotalList({}),
      dataMiscellaneousList: getBillSummaryDetails({}),
      snack: { ...snackService.snackParameters },
      senderDetails: getSenderReceiverDetails({}),
      receiverDetails: getSenderReceiverDetails({}),
      isCancel:false,
      sendmail:false,
      externalEmail:'',
      emailValidation: {
        flag: false,
        fieldList: tableConfig.formWithEmailValidation,
      },
    };
    this.client = CLIENT.DBS;
    this.dataListLength = 0;
    this.pageConfig = this.getPageConfig();
  }

  getPageConfig = () => {
    const {
      company: {
        printInfo: { batchEnabled },
      },
    } = this.state;
    //const { company: { printInfo: { batchEnabled }}} = this.props;
    const header = [...config.header];
    if (batchEnabled) {
      return { ...config, header };
    }
    return config;
  };

  componentDidMount() {
    this.setupConfig();
    this.groupPrintDataSetNew();
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('contextmenu', this.handleMouseClick);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
  }
  async setupConfig () {
    const { match, user = {}, location = {}, company } = this.props;
    const { userDetail, firstCopyTaxInvoice} = this.state;
    await this.getDetail();
    const id=match.params.id.replace(/%2F/g, '/')
    const invoiceHash =match.params.invoiceHash || '';
    if ( !!invoiceHash.length) {
      this.client = CLIENT.ROSIA;
      document.getElementsByClassName('scrollbar-container')[0].classList.add('visibility-hidden');
      document.getElementsByClassName('nav-bar')[0].classList.add('visibility-hidden');
      userDetail.id = match.params.userId;
      this.setState(
        {
          invoiceNumber: id,
          firstCopyTaxInvoice: JSON.parse(match.params.firstCopy),
          userDetail,
          loading: true,
        },
        () => {
          if (firstCopyTaxInvoice || this.client === CLIENT.ROSIA) {
            setTimeout(() => {
              this.printInvoice();
            }, 500);
          }
          window.onafterprint = this.handleAfterPrint;
        },
      );
    } else {
      const printDetails = {
        transaction: 'SALES',
        invoiceNumber: id,
        printedBy: user.idUsers,
      };
      this.setState(
        {
          invoiceNumber: id,
          firstCopyTaxInvoice: false,
          printDetails,
        },
        () => {
          window.onafterprint = this.handleAfterPrint;
        },
      );
    }
  }

  groupPrintDataSetNew = async () => {
    const { skuList, company } = this.state;
    const printInfoBill = company.printInfo || {};
    const orders = clone(skuList);
    const dataList = orders?.length ? await groupPrintDataSet(orders, printInfoBill, config) : [];
    this.setState({ dataList });
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  btnWrapper = () => (
    <>
      <Button
        accent
        className="margin-right-0 cancel-btn modal-btn"
        onClick={() => history.push(`/${SERVICE_INVOICE_BASE}`)}
      >
        Cancel
      </Button>
      <Button
        accent
        className="save-btn modal-btn"
        onClick={() => {
          this.printInvoice();
        }}
      >
        Print
      </Button>
      <Button
        accent
        className="save-btn modal-btn"
        onClick={() => {
          this.handleSendEmail();
        }}
      >
        Send Email
      </Button>
    </>
  );

  handleAfterPrint = () => {
    this.setState({ printButtonClicked: false }, () => {
      if (this.client === CLIENT.ROSIA) {
        window.close();
        //history.push(`/login`);
      }
    });
  };

  onInputChange =(param,value)=>{
    this.setState({externalEmail:value});
  }
  handleConfirmModalClose =() =>{
    this.setState({sendmail:false,externalEmail:''});
  }
  getConfirmation =(mail=false) =>{
    const {externalEmail,data} =this.state;
      let validStatus=true;
      if(externalEmail !== '') {
        validStatus = externalEmail?.match(EMAIL_RULE);
        validStatus ===null && this.setState({emailValidation:{flag:true}})
      }
     const emailObj =data?.summary?.customerDetail?.email;
    if (validStatus && (emailObj?.length || externalEmail?.length)) {
      const object ={
        emailObject:[{email:emailObj,invoice_number:data?.summary?.invoiceNumber}],
        external_email:externalEmail,
      }
      salesInvoiceApi.sendEmail(object)
      .then(response => {
        this.setState({ loading: false });
        if (response) {
          const snack = snackService.generateSuccessMessage('Email successfully sent.');
          this.setState({ snack }, () => this.getDetail());
        }
      })
      .catch(err =>  this.getErrorMsg(err));
      this.handleConfirmModalClose();
  }
  else{
    this.getErrorMsg();
  }
}
getErrorMsg =(err)=>{
  const {data} =this.state;
  const errMsg =data?.summary?.customerDetail?.email?.length ===0 && 'Email not found'
  const snack = snackService.generateFailureMessage(err?.error?.message || errMsg || 'Error while sending email.');
  this.setState({
    snack,
  });
}

  render() {
    const { loading, snack, printButtonClicked, data, company, paymentDetail, dataList, skuList, pdfButtonClicked,externalEmail,emailValidation,sendmail} =
      this.state;
    const { printInfo: printInfoBill } = company;
    return (
      <InvoiceDetailsStyled>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}

        <div className={`sales-invoice-detail pad-b-24${loading ? 'clickable-false' : ''}`}>
          <div>
            <div className={`sales-invoice ${loading ? 'clickable-false' : ''}`}>
              <div className={printButtonClicked ? 'no-print' : 'display-block'}>
                <DetailView
                  display={{
                    header: true,
                    footer: true,
                    summary: true,
                  }}
                  serverResponseWaiting={loading}
                  headerConfig={{
                    company,
                    title: config.title,
                    date: moment(data.summary.date || new Date()).format('DD MMM YYYY'),
                    paymentDetail,
                  }}
                  pageConfig={this.pageConfig}
                  data={data}
                  printInfoBill={printInfoBill}
                />
                <PageButtonWrapper renderBtn={this.btnWrapper} />
              </div>
              <div
                className={printButtonClicked ? 'display-block portrait visibility-shown' : 'no-print'}
                id="divToPrint"
              >
                {pdfButtonClicked ? (
                  <InvoiceDetailsStyled className="pdf">
                    <div className={`sales-invoice-detail pad-b-24${loading ? 'clickable-false' : ''}`}>
                      {this.renderPrintContent()}
                    </div>
                  </InvoiceDetailsStyled>
                ) : (
                  <>{this.renderPrintContent()}</>
                )}
              </div>
            </div>
          </div>
          { (sendmail) && 
                <ConfirmationDialog
                onConfirmModalClose={this.handleConfirmModalClose}
                getConfirmation={this.getConfirmation}
                sendmail={sendmail}
                onInputChange={this.onInputChange}
                externalEmail={externalEmail}
                emailValidate={emailValidation?.flag}
                customerName={data?.summary?.customerDetail.title}
                />
          }
          <BillingSnackBar closeSnack={this.closeSnack} config={snack} classes="no-pdf" />
        </div>
      </InvoiceDetailsStyled>
    );
  }
}

InvoiceDetails.contextTypes = {
  router: PropTypes.object,
};

InvoiceDetails.propTypes = propTypes;
InvoiceDetails.defaultProps = defaultProps;

const mapStateToProps = state => ({
  user: state.billing.user || null,
  company: state.billing.company || null,
});

const ServiceInvoiceDetail = connect(mapStateToProps)(InvoiceDetails);

export default ServiceInvoiceDetail;
